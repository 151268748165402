import { artService } from '../../services/art.service';
import { IArt, Art } from '@/models/art.interface';
import { BaseService } from '@/services/base.service';
import { ArtImage } from '@/models/image.interface';

const state = { content: [] };

const cartKey = 'cart';

const getters: any = {
  cartItems: (userState: any) => userState.content,
  numberOfItemsInCart: (userState: any) => userState.content.length,
};

const actions: any = {
  addToCart: ({ commit, dispatch }: { commit: any; dispatch: any }, art: IArt) => {
    commit('addToCart', art);
  },
  removeFromCart: ({ commit, dispatch }: { commit: any; dispatch: any }, id: string) => {
    commit('removeFromCart', id);
  },
  checkCart: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    const cart: string = JSON.parse(localStorage.getItem(cartKey) || '');
    return new Promise((resolve, reject) => {
      if (cart === '' || cart.length === 0) {
        resolve(undefined);
      } else {
        artService.checkCart(cart).subscribe(
          (result: IArt[]) => {
            commit('setCart', result);
            resolve(result);
          },
          (errors: any) => {
            commit('updateFailed', errors);
            reject();
          },
        );
      }
    });
  },
  purchaseArt: ({ commit, dispatch }: { commit: any; dispatch: any }, pickup: boolean) => {
    const cart: string = JSON.parse(localStorage.getItem(cartKey) || '');
    return new Promise((resolve, reject) => {
      artService.purchaseArt(cart, pickup).subscribe(
        (result: any) => {
          resolve(result);
        },
        (errors: any) => {
          // TODO: Error page
        },
      );
    });
  },
  moveArtAfterPurchase: ({ commit, dispatch }: { commit: any; dispatch: any }, transactionId: string) => {
    return new Promise((resolve, reject) => {
      artService.moveArtAfterPurchase(transactionId).subscribe(
        (result: any) => {
          resolve(result);
        },
        (errors: any) => {
          // TODO: Error page
        },
      );
    });
  },
};

const mutations: any = {
  addToCart: (userState: any, art: IArt) => {
    userState.content.push(art);
    localStorage.setItem(cartKey, JSON.stringify(userState.content.map((a: IArt) => a.id)));
  },
  removeFromCart: (userState: any, id: string) => {
    userState.content.splice(userState.content.map((a: IArt) => a.id).indexOf(id), 1);
    localStorage.setItem(cartKey, JSON.stringify(userState.content.map((a: IArt) => a.id)));
  },
  setCart: (userState: any, cart: any) => {
    userState.content = addImages(cart.art);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

function addImages(payload: IArt[]): IArt[] {
  payload.forEach((item: Art) => {
    item.artImage.thumbnail = `${BaseService.api}/art/thumbnail/${item.artImage.fileName}/${item.artImage.fileType}`;
    item.artImage.content = `${BaseService.api}/art/image/${item.artImage.fileName}/${item.artImage.fileType}`;
    item.detailedImages.forEach((λ: ArtImage) => {
      λ.thumbnail = `${BaseService.api}/art/thumbnail/${λ.fileName}/${λ.fileType}`;
      λ.content = `${BaseService.api}/art/image/${λ.fileName}/${λ.fileType}`;
    });
  });
  return payload;
}
