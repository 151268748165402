import Vue from 'vue';
import store from '@/store/store';
import ImageUpload from '@/components/common/imageUploader.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { VideoLink } from '@/models/video.interface';
import { RefsExtType } from '@/models/refs.interface';
import { Art, IArt } from '@/models/art.interface';
import { BaseService } from '@/services/base.service';
import { EventBus } from '@/event-bus';
import { mapGetters } from 'vuex';
import { PageKeys } from '@/models/request.interface';

@Component({
  components: {
    ImageUpload,
  },
  computed: mapGetters({
    art: 'art/Page',
    edit: 'auth/Edit',
    add: 'auth/Add',
    videos: 'videos/VideoLinks',
  }),
})
export default class AboutEditor extends Vue {
  public $refs!: {
    imageUploader: RefsExtType;
  };
  // Getters
  private art!: any;
  private edit!: any;
  private add!: any;

  // Props
  @Prop({ required: true })
  private readonly dialog!: boolean;

  @Prop({ required: true })
  private readonly currentArt!: IArt;

  private modalDialog: boolean = false;

  // Choose editor
  private items = ['Video', 'Image'];
  private editType: string = '';

  // Image editor
  private imageIds: string[] = [];
  private editedItem: IArt = new Art(PageKeys.About);
  private dropzoneOptions: object = {
    url: BaseService.api + '/art/ImageUpload',
    thumbnailWidth: 100,
    maxFileSize: 1,
    headers: { Authorization: `bearer ${store.getters['auth/authToken']}` },
  };

  // Video editor
  private editedVideoItem: VideoLink = new VideoLink('');
  private videoLink: string = '';

  @Watch('dialog')
  private onDialogChange(newValue: boolean, oldValue: boolean) {
    Object.assign(this.editedItem, this.currentArt);
    Object.assign(this.editedItem.artImage, this.currentArt.artImage);
    this.modalDialog = newValue;
  }

  private saveItem() {
    this.$refs.imageUploader.setImagesToArt(this.editedItem);
    if (this.edit) {
      store.dispatch('art/updateRequest', this.editedItem);
    } else {
      store.dispatch('art/addRequest', this.editedItem);
    }
  }

  private addVideoEntry() {
    store.dispatch('videos/newLink', new VideoLink(this.videoLink));
  }

  private deleteVideoEntry(item: VideoLink) {
    store.dispatch('videos/deleteLink', item);
  }

  private closeDialog() {
    store.dispatch('auth/adminReset');
    this.editType = '';
    EventBus.$emit('CloseAboutDialog');
  }
}
