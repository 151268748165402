import Gallery from '@/components/gallery/gallery';
import EventTypes from './event.enum';

export interface IEventPackage {
    eventType: EventTypes;
    event: any;
}

export class EventPackage implements IEventPackage {
    public eventType: EventTypes;
    public event: any;

    constructor(eventType: EventTypes, event: any) {
        this.eventType = eventType;
        this.event = event;
    }
}
