import { artService } from '../../services/art.service';
import { ArtType, IRequest, PageKeys } from '@/models/request.interface';
import { IArt, Art } from '@/models/art.interface';
import { BaseService } from '@/services/base.service';
import { EventBus } from '@/event-bus';
import { ArtImage, IImage } from '@/models/image.interface';
import { Emit } from 'vue-property-decorator';
import pages from './pages';

const state = {
  Pages: [],
  status: '',
  Loading: true,
};

const getters: any = {
  Page: (userState: any) => (route: string) => {
    const rst = userState.Pages.find((λ: any) => λ.PageRoute === route);
    if (rst === undefined) {
      return {
        Content: new Array(),
      };
    }
    return rst;
  },
  PageWithArtType: (userState: any) => (route: string, artType: any) => {
    const rst = userState.Pages.find((λ: any) => λ.PageRoute === route);
    if (rst === undefined || rst.Content === undefined) {
      return {
        Content: new Array(),
        PageRoute: route,
        NumberOfPages: 1,
      };
    }
    const art = rst.Content.filter((λ: any) => λ.artType === ArtType[artType] || λ.artType === artType);
    return {
      Content: art,
      PageRoute: route,
      NumberOfPages: Math.ceil(art.length / 10),
    };
  },
  GalleryExist: (userState: any) => (route: string) => {
    if (userState.Pages.length === 0) {
      return false;
    }
    return userState.Pages.find((λ: any) => λ.PageRoute === route).Content.length !== 0;
  },
  About: (userState: any) => userState.Pages.find((λ: any) => λ.PageRoute === PageKeys.About),
  Finished: (userState: any) => userState.Pages.find((λ: any) => λ.PageRoute === PageKeys.Store),
  Archived: (userState: any) => userState.Pages.find((λ: any) => λ.PageRoute === PageKeys.Sold),
  Videos: (userState: any) => userState.Pages.find((λ: any) => λ.PageRoute === PageKeys.About),
  IsLoading: (userState: any) => userState.Loading,
  FinishedIds: (userState: any) => userState.Finished.Content.map((λ: any) => λ.id),
};

const actions: any = {
  contentRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, request: IRequest) => {
    return new Promise((resolve, reject) => {
      commit('loading');
      artService.get(request).subscribe(
        (result: IArt[]) => {
          commit('contentSuccess', {
            Art: result,
            PageRoute: request.PageName,
            ArtType: request.ArtType,
          });
          commit('updateSuccessMessage', request.PageName + ' Request Successful');
          resolve(result);
        },
        (errors: any) => {
          commit('updateFailed', errors);
          reject();
        },
      );
    });
  },
  deleteRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, deleteObj: any) => {
    artService.delete(deleteObj.id).subscribe(
      (result: string) => {
        commit('deleteSuccess', deleteObj);
        commit('updateSuccessMessage', 'Art Request Successful');
      },
      (errors: any) => {
        commit('updateFailed', errors);
      },
    );
  },
  updateRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, art: IArt) => {
    return new Promise((resolve, reject) => {
      artService.patch(art).subscribe(
        (result: string) => {
          // commit('updateSuccess', art);
          commit('updateSuccessMessage', 'Update Request Successful');
          resolve('Update Request Successful');
        },
        (errors: any) => {
          reject(errors);
        },
      );
    });
  },
  addRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, art: IArt) => {
    return new Promise((resolve, reject) => {
      artService.add(art).subscribe(
        (result: string) => {
          // commit('addSuccess', art);
          commit('updateSuccessMessage', 'Add Request Successful');
          resolve();
        },
        (errors: any) => {
          // commit('updateFailed', errors);
          reject();
        },
      );
    });
  },
  moveArt: ({ commit, dispatch }: { commit: any; dispatch: any }, art: IArt) => {
    artService.move(art).subscribe(
      (result: string) => {
        commit('addSuccess', art);
        commit('updateSuccessMessage', 'Move Request Successful');
      },
      (errors: any) => {
        commit('updateFailed', errors);
      },
    );
  },
};

const mutations: any = {
  loading: (userState: any) => {
    userState.Loading = true;
  },
  updateFailed: (userState: any, errors: any) => {
    EventBus.$emit('ArtUpdate', false, errors);
  },
  updateSuccessMessage: (userState: any, message: string) => {
    userState.status = message;
    EventBus.$emit('Alert', message, message.indexOf('Successful') !== -1 ? 'info' : 'error');
    EventBus.$emit('ArtUpdate', true, message);
  },
  contentSuccess: (userState: any, payload: any) => {
    const payloadArt = payload.Art;
    const payloadPage = payload.PageRoute;
    const payloadArtType = payload.ArtType;
    payloadArt.sort((a: IArt, b: IArt) => a.artImage.order - b.artImage.order);
    payloadArt.forEach((art: IArt) => {
      art.detailedImages.sort((a: IImage, b: IImage) => a.order - b.order);
    });
    if (payloadArt.length !== 0) {
      const rst = userState.Pages.find((λ: any) => λ.PageRoute === payloadPage);
      if (rst !== undefined && rst !== null) {
        rst.Content.push(...addImages(payloadArt));
        rst.NumberOfPages++;
      } else {
        userState.Pages.push({
          Content: addImages(payloadArt),
          PageRoute: payloadPage,
          NumberOfPages: 1,
        });
      }
    }
    userState.Loading = false;
  },
  resetFinished: (userState: any, message: string) => {
    userState.Finished.Content = [];
    userState.status = message;
  },
  deleteSuccess: (userState: any, deleteObj: any) => {
    const rst = userState.Pages.find((λ: any) => λ.PageRoute === deleteObj.page);
    const art = (rst.Content as IArt[]).findIndex((λ) => λ.id === deleteObj.id);
    if (art !== -1) {
      (rst.Content as IArt[]).splice(art, 1);
    }
  },
  addRandomArt: (userState: any, result: string) => {
    userState.Contact.Image = BaseService.api + '/art/image/' + result;
    userState.Contact.Thumbnail = BaseService.api + '/art/thumbnail/' + result;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

function addImages(payload: IArt[]): IArt[] {
  payload.forEach((item: Art) => {
    item.artImage.thumbnail = `${BaseService.api}/art/thumbnail/${item.artImage.fileName}/${item.artImage.fileType}`;
    item.artImage.content = `${BaseService.api}/art/image/${item.artImage.fileName}/${item.artImage.fileType}`;
    item.detailedImages.forEach((λ: ArtImage) => {
      λ.thumbnail = `${BaseService.api}/art/thumbnail/${λ.fileName}/${λ.fileType}`;
      λ.content = `${BaseService.api}/art/image/${λ.fileName}/${λ.fileType}`;
    });
  });
  return payload;
}
