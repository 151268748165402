export interface IShow {
    id: string | undefined;
    name: string;
    date: string;
    endDate: string;
    location: string;
    linkToShow: string;
}

export class Show implements IShow {
    public id: string | undefined;
    public name: string;
    public date: string;
    public endDate: string;
    public location: string;
    public linkToShow: string;

    constructor() {
        this.name = '';
        this.date = new Date().toISOString().substr(0, 7);
        this.endDate = new Date().toISOString().substr(0, 7);
        this.location = '';
        this.linkToShow = '';
    }
}
