import axios from 'axios';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Rx';

class TogglesService extends BaseService {

    private static instance: TogglesService;

    private constructor() { super(); }

    public static get Instance(): TogglesService {
        return this.instance || (this.instance = new this());
    }

    public get(): Observable<any> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/toggle`))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error));
    }

    public update(paymentsEnabled: boolean, classesPageEnabled: boolean, eventsPageEnabled: boolean): Observable<any> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/toggle?payment=${paymentsEnabled.toString()}&classes=${classesPageEnabled.toString()}&events=${eventsPageEnabled.toString()}`))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
}

export const toggleService: TogglesService = TogglesService.Instance;
