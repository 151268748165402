import Vue from 'vue';
import router from '@/router';
import store from '@/store/store';
import vue2Dropzone from 'vue2-dropzone';
import draggable from 'vuedraggable';
import ImageUpload from '@/components/common/imageUploader.vue';
import { mapGetters } from 'vuex';
import { IArt, Art } from '@/models/art.interface';
import { RefsExtType } from '@/models/refs.interface';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseService } from '@/services/base.service';
import { required, email, max, numeric, regex } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode, validate } from 'vee-validate';
import { EventBus } from '@/event-bus';
import { ArtType, ChainLengths, PageKeys, TraySizes } from '@/models/request.interface';
import { ComplexInnerSubscriber } from 'rxjs/internal/innerSubscribe';
import { triggerThreadSafeValidation } from 'vee-validate/dist/types/components/common';

extend('required', {
  ...required,
  message: '{_field_} cannot be empty',
});

extend('numeric', {
  ...numeric,
  message: '{_field_} must be numeric',
});

extend('regex', {
  ...regex,
  message: '{_field_} must be number with maximum two decimal points',
});

@Component({
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
    edit: 'auth/Edit',
    deleteArt: 'auth/Delete',
    loadingArt: 'art/IsLoading',
    paymentEnabled: 'toggles/payment',
    cartItems: 'cart/cartItems',
  }),
  components: {
    vueDropzone: vue2Dropzone,
    ValidationProvider,
    ValidationObserver,
    draggable,
    ImageUpload,
  },
})
export default class GalleryImageEditor extends Vue {
  public $refs!: {
    observer: RefsExtType;
    imageUploader: RefsExtType;
  };
  // Getters
  private edit!: boolean;
  // Props
  @Prop({ required: true })
  private readonly dialog!: boolean;

  @Prop({ required: true })
  private readonly isFinished!: boolean;

  @Prop({ required: true })
  private readonly art!: Art;

  private formTitle: string = '';
  private imageDialog: boolean = false;

  private modalArt: IArt = new Art(this.isFinished ? PageKeys.Store : PageKeys.Sold);
  private dimensions: any = this.setEmptyShippingDimensions();

  private savingArt: boolean = false;
  private serverErrorMessage: string = '';

  // Art type
  private selectedArtType = ArtType[ArtType.Standing];
  private artTypesString = Object.values(ArtType).filter((x: any) => typeof x === 'string');

  // Dimension options
  private traySizesString = Object.values(TraySizes).filter((x: any) => typeof x === 'string');
  private selectedTraySize = TraySizes[TraySizes.Square];

  // Jewllery
  private color: string = '';
  private size: string = '';
  private chainLength = ChainLengths[ChainLengths.Eighteen];
  private chainLengthsString = Object.values(ChainLengths).filter((x: any) => typeof x === 'string');

  private dropzoneOptions: object = {
    url: BaseService.api + '/art/ImageUpload',
    thumbnailWidth: 200,
    maxFilesize: 1,
    headers: { Authorization: `bearer ${store.getters['auth/authToken']}` },
  };

  @Watch('dialog')
  private onDialogChange(value: boolean, oldValue: boolean) {
    if (value) {
      if (this.edit) {
        this.formTitle = 'Edit';
        this.setShippingDimensions(this.art);
        Object.assign(this.modalArt, this.art);
        Object.assign(this.modalArt.artImage, this.art.artImage);
        this.selectedArtType = ArtType[this.modalArt.artType];
      } else {
        this.formTitle = 'Add';
        this.modalArt = new Art(this.isFinished ? PageKeys.Store : PageKeys.Sold);
        this.dimensions = this.setEmptyShippingDimensions();
      }
    }
    this.imageDialog = value;
  }

  get showTrayShipping() {
    return this.selectedArtType === ArtType[ArtType.Trays];
  }

  get showStandingArtShipping() {
    return this.selectedArtType === ArtType[ArtType.Standing];
  }

  get showJewlleryOptions() {
    return this.selectedArtType === ArtType[ArtType.Jewelry];
  }

  private setEmptyShippingDimensions() {
    return {
      pounds: null,
      ounces: null,
      width: null,
      length: null,
      height: null,
    };
  }

  private saveItem() {
    this.$refs.observer.validate().then((rst) => {
      if (rst) {
        this.savingArt = true;
        this.modalArt.price = Number(this.modalArt.price);
        this.modalArt.artType = ArtType[this.selectedArtType];
        this.getShippingDimensions();
        this.$refs.imageUploader.setImagesToArt(this.modalArt);
        if (this.modalArt.artType === ArtType.Jewelry) {
          this.saveJewelery();
        }
        if (this.edit) {
          this.saveRequest('art/updateRequest', 'Save successful, reloading.');
        } else {
          this.saveRequest('art/addRequest', 'Creation successful, reloading.');
        }
      } else {
        EventBus.$emit('Alert', 'Please resolve the errors', 'error');
      }
    });
  }

  private saveJewelery() {
    this.modalArt.description = `${this.color};${this.size};${this.chainLength}`;
  }

  private saveRequest(dispatch: string, message: string) {
    store
      .dispatch(dispatch, this.modalArt)
      .then(() => {
        EventBus.$emit('CloseGalleryImageEditor');
        EventBus.$emit('ReloadWithMessage', message);
      })
      .catch((err) => {
        EventBus.$emit('Alert', 'Server Error', 'error');
        this.serverErrorMessage = err;
      })
      .finally(() => {
        this.savingArt = false;
      });
  }

  private closeDialog() {
    EventBus.$emit('CloseGalleryImageEditor');
    store.dispatch('auth/adminReset');
  }

  private moveToSold() {
    this.modalArt.pageName = this.isFinished ? PageKeys.Store : PageKeys.Sold;
    store.dispatch('art/moveArt', this.modalArt).then(() => this.modalArt.pageName);
  }

  private setShippingDimensions(item: Art) {
    if (item.shippingDimensions !== '' && item.shippingDimensions != null) {
      const shippingDetails = item.shippingDimensions.split(';');
      this.dimensions.pounds = Number(shippingDetails[0]);
      this.dimensions.ounces = Number(shippingDetails[1]);
      this.dimensions.width = Number(shippingDetails[2]);
      this.dimensions.length = Number(shippingDetails[3]);
      this.dimensions.height = Number(shippingDetails[4]);
    }
  }

  private getShippingDimensions() {
    this.modalArt.shippingDimensions = `${this.dimensions.pounds};${this.dimensions.ounces};${this.dimensions.width};${this.dimensions.length};${this.dimensions.height}`;
  }
}
