import axios from 'axios';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Rx';
import { VideoLink } from '@/models/video.interface';

class VideoService extends BaseService {

    private static instance: VideoService;

    private constructor() { super(); }

    public static get Instance(): VideoService {
        return this.instance || (this.instance = new this());
    }

    public get(): Observable<any> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/video`))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
    public delete(video: VideoLink): Observable<any> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/video/delete`, video))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
    public create(video: VideoLink): Observable<any> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/video/create`, video))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
    public update(video: VideoLink): Observable<any> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/video/update`, video))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
}

export const videoService: VideoService = VideoService.Instance;
