export interface IImage {
    id: string | undefined;
    fileName: string;
    fileType: string;
    content: string;
    thumbnail: string;
    order: number;
}

export class ArtImage implements IImage {
    public id: string | undefined;
    public fileName: string;
    public fileType: string;
    public content: string;
    public thumbnail: string;
    public order: number;

    constructor() {
        this.fileName = '';
        this.fileType = '';
        this.content = '';
        this.thumbnail = '';
        this.order = 0;
    }
}
