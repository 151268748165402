import { IImage, ArtImage } from './image.interface';
import { ArtType, PageKeys } from './request.interface';

export interface IArt {
    id: string | undefined;
    title: string;
    description: string;
    dimensions: string;
    shippingDimensions: string;
    pageName: PageKeys;
    price: number|null;
    artImage: IImage;
    detailedImages: IImage[];
    order: number;
    artType: ArtType;
    gallery: string;
    purchaseAtGallery: boolean;
}

export class Art implements IArt {
    public id: string | undefined;
    public title: string;
    public description: string;
    public dimensions: string;
    public shippingDimensions: string;
    public pageName: PageKeys;
    public price: number|null;
    public artImage: IImage;
    public detailedImages: IImage[];
    public order: number;
    public artType: ArtType;
    public gallery: string;
    public purchaseAtGallery: boolean;

    constructor(pageName: PageKeys) {
        this.title = '';
        this.description = '';
        this.dimensions = '';
        this.shippingDimensions = '';
        this.price = null;
        this.artImage = new ArtImage();
        this.detailedImages = [];
        this.order = 0;
        this.pageName = pageName;
        this.artType = ArtType.Standing;
        this.gallery = '';
        this.purchaseAtGallery = false;
    }
}
