import { Component, Vue } from 'vue-property-decorator';
import { ICredentials } from '../../models/credentials.interface';
import { mapGetters } from 'vuex';
import store from '@/store/store';
import router from '@/router';

@Component({
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
  }),
  components: {},
})
export default class RegistrationForm extends Vue {
  private isAuthenticated!: boolean;

  private isBusy: boolean = false;
  private errors: string = '';
  private credentials = {} as ICredentials;

  private mounted(): void {
    if (this.isAuthenticated) {
      router.push('/');
    }
  }

  private handleSubmit(): void {
    this.isBusy = true;
    store
      .dispatch('auth/authRequest', this.credentials)
      .then((result) => {
        router.push('/');
      })
      .catch((err) => {
        this.errors = err;
      })
      .then(() => {
        this.isBusy = false;
      });
  }
}
