import { Days } from './days.interface';

export interface IClass {
    id: string | undefined;
    className: string;
    currentCount: number;
    maxCount: number;
    cost: number;
    daysTaught: string;
}

export class Class implements IClass {
    public id: string | undefined;
    public className: string;
    public currentCount: number;
    public maxCount: number;
    public cost: number;
    public daysTaught: string;

    constructor() {
        this.className = '';
        this.currentCount = 0;
        this.maxCount = 0;
        this.cost = 0;
        this.daysTaught = '';
    }
}
