






































import Vue from 'vue';
import store from '@/store/store';
import router from '@/router';
import vue2Dropzone from 'vue2-dropzone';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { EventBus } from '@/event-bus';
import { IImage, ArtImage } from '@/models/image.interface';
import { BaseService } from '@/services/base.service';
import { IArt } from '@/models/art.interface';

@Component({
  computed: mapGetters({}),
  components: {
    vueDropzone: vue2Dropzone,
    draggable,
  },
})
export default class ImageUploader extends Vue {
  @Prop({ required: true })
  private readonly art!: IArt;

  private images: IImage[] = [];
  private dropzoneOptions: object = {
    url: BaseService.api + '/art/ImageUpload',
    thumbnailWidth: 200,
    maxFilesize: 1,
    headers: { Authorization: `bearer ${store.getters['auth/authToken']}` },
  };

  private mounted() {
    if (this.art.artImage.content !== '' && this.art.detailedImages.length !== 0) {
      this.images.push(Object.assign({}, this.art.artImage));
      this.art.detailedImages.forEach((element: IImage) => {
        this.images.push(Object.assign({}, element));
      });
    }
  }

  private removeImage(filename: string) {
    this.images = this.images.filter((λ: IImage) => λ.fileName !== filename);
  }

  private fileUploaded(file: any, response: any) {
    const newImage = new ArtImage();
    newImage.fileName = response[0];
    newImage.fileType = response[1];
    this.setImageUrls(newImage);
    this.images.push(Object.assign({}, newImage));
  }

  private setImageUrls(image: IImage) {
    image.content = `${BaseService.api}/art/image/${image.fileName}/${image.fileType}`;
    image.thumbnail = `${BaseService.api}/art/thumbnail/${image.fileName}/${image.fileType}`;
  }

  private setImagesToArt(art) {
    Object.assign(art.artImage, this.images.splice(0, 1)[0]);
    if (this.images.length !== 0) {
      Object.assign(art.detailedImages, this.images);
      let count = 1;
      art.detailedImages.forEach((element: IImage) => {
        element.order = count;
        count++;
      });
    }
  }
}
