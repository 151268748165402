import { mapGetters } from 'vuex';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { IArt, Art } from '@/models/art.interface';
import store from '@/store/store';
import GalleryImageEditor from '@/components/modals/gallery/galleryImageEditor';
import { EventBus } from '@/event-bus';
import { ArtType, PageKeys } from '@/models/request.interface';
import Header from '@/components/common/header.vue';
import router from '@/router';

const AppProps = Vue.extend({
  props: {
    artId: String,
    pageRequest: Object,
    page: Object,
    isFinished: Boolean,
  },
});

@Component({
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
    edit: 'auth/Edit',
    add: 'auth/Add',
    deleteArt: 'auth/Delete',
    loadingArt: 'art/IsLoading',
    paymentEnabled: 'toggles/payment',
    cartItems: 'cart/cartItems',
  }),
  components: {
    Header,
    GalleryImageEditor,
  },
})
export default class ArtPiece extends AppProps {
  // Getters
  private isAuthenticated!: boolean;
  private edit!: boolean;
  private add!: boolean;

  // Art variables
  private selectedArt: IArt = new Art(this.isFinished ? PageKeys.Store : PageKeys.Sold);
  // Cart
  private addingItemToCart: boolean = false;

  private adminDialog: boolean = false;

  // Customer Modal
  private selectedThumbnail: string = '';
  private selectedImage: string = '';

  private loading: boolean = true;

  private isBuyable = false;

  private created(): void {
    if (store.getters['art/GalleryExist'](this.$route.params.state)) {
      this.getArt();
    } else {
      store.dispatch('art/contentRequest', {
        ArtType: ArtType[this.$route.params.type],
        PageName: this.$route.params.state,
        PageNumber: 1,
        Order: true,
      }).then(() => {
        this.getArt();
      });
    }

    if (this.isAuthenticated) {
      store.dispatch('auth/adminReset');
      store.watch(
        () => this.add,
        (rst) => {
          if (rst === true && this.adminDialog === false) {
            this.adminDialog = true;
          }
        },
      );
      EventBus.$on('CloseGalleryImageEditor', () => {
        this.adminDialog = false;
      });
    }

  }

  private getArt() {
    this.selectedArt = store.getters['art/PageWithArtType'](
      this.$route.params.state,
      ArtType[this.$route.params.type],
    ).Content.find((λ: IArt) => λ.id === this.$route.params.artId);

    this.selectedImage = this.selectedArt.artImage.content;
    this.selectedThumbnail = this.selectedArt.artImage.thumbnail;
    this.loading = false;
    this.isBuyable = this.$route.params.state === PageKeys.Store;
  }

  private allImages() {
    return [this.selectedArt.artImage].concat(this.selectedArt.detailedImages);
  }

  private selectArtImage(content: string, thumbnail: string) {
    this.selectedImage = content;
    this.selectedThumbnail = thumbnail;
  }

  get artModalIsJewellery() {
    return this.selectedArt.artType === ArtType.Jewelry;
  }

  get isArtInCart() {
    return store.getters['cart/cartItems'].some((a: IArt) => a.id === this.selectedArt.id);
  }

  private JeweleryColor() {
    if (this.selectedArt.artType !== ArtType.Jewelry) {
      return '';
    }
    return this.selectedArt.description.split(';')[0];
  }

  private JewelerySize() {
    if (this.selectedArt.artType !== ArtType.Jewelry) {
      return '';
    }
    return this.selectedArt.description.split(';')[1];
  }

  private JeweleryChainLength() {
    if (this.selectedArt.artType !== ArtType.Jewelry) {
      return '';
    }
    return this.selectedArt.description.split(';')[2];
  }

  private addArtToCart() {
    store.dispatch('cart/addToCart', this.selectedArt);
  }
}
