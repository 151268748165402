import axios from 'axios';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Rx';
import { IRequest } from '@/models/request.interface';
import { IArt } from '@/models/art.interface';

class ArtService extends BaseService {

    private static instance: ArtService;

    private constructor() { super(); }

    public static get Instance(): ArtService {
        return this.instance || (this.instance = new this());
    }

    public get(request: IRequest): Observable<IArt[]> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/art`, request))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public getImage(type: string, imageId: string): Observable<string> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/art/image/${imageId}/${type}`))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public getRandomImage(): Observable<string> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/art/image/random`))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public delete(id: string): Observable<string> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/art/remove/${id}`))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public patch(art: IArt): Observable<string> {
        return Observable.fromPromise(axios.patch(`${BaseService.api}/art`, art))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public add(art: IArt): Observable<string> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/art/UploadArt`, art))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public move(art: IArt): Observable<string> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/art/move`, art))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public checkCart(artIds: string): Observable<IArt[]> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/cart/GetCartAvalability/${artIds}`))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public purchaseArt(artIds: string, pickUp: boolean): Observable<IArt[]> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/cart/Purchase/${artIds}/${pickUp}`))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }

    public moveArtAfterPurchase(transactionId: string): Observable<IArt[]> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/cart/MoveArtAfterPuchase/${transactionId}`))
            .map((res: any) => this.checkResponse(res))
            .catch((error: any) => this.handleError(error.response));
    }
}

export const artService: ArtService = ArtService.Instance;
