












import { mapGetters } from 'vuex';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import store from '@/store/store';
import router from '@/router';
import { EventBus } from '@/event-bus';

@Component({
  computed: mapGetters({}),
})
export default class Header extends Vue {
  @Prop({ required: true })
  private readonly loading!: boolean;

  @Prop({ required: true })
  private readonly text!: string;
}
