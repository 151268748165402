import axios from 'axios';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Rx';
import { Gallery } from '@/models/events/gallery.interface';
import { Show } from '@/models/events/show.interface';
import { IEventPackage } from '@/models/events/eventPackage.interface';

class EventService extends BaseService {

    private static instance: EventService;

    private constructor() { super(); }

    public static get Instance(): EventService {
        return this.instance || (this.instance = new this());
    }

    public get(): Observable<any> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/event`))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }

    public newEvent(newEvent: IEventPackage): Observable<any> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/event/` + newEvent.eventType, newEvent.event))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }

    public updateEvent(newEvent: IEventPackage): Observable<any> {
        return Observable.fromPromise(axios.patch(`${BaseService.api}/event/` + newEvent.eventType, newEvent.event))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }

    public deleteEvent(newEvent: IEventPackage): Observable<any> {
        return Observable.fromPromise(
            axios.post(`${BaseService.api}/event/` + newEvent.eventType + `/Delete`, newEvent.event))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
}

export const eventService: EventService = EventService.Instance;
