import { videoService } from '../../services/video.service';
import store from '../store';
import { EventBus } from '@/event-bus';
import { VideoLink } from '@/models/video.interface';
import Axios from 'axios';

const state = { Content: [], status: '' };

const getters: any = {
  VideoLinks: (userState: any) => userState.Content,
};

const actions: any = {
  linkRequest: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    return new Promise((resolve, reject) => {
      videoService.get().subscribe(
        (result: VideoLink[]) => {
          const rst: object[] = [];
          result.forEach((video: VideoLink) => {
            Axios.create()
              .get(
                `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyAOO5XPDfkeXlDeY6cQiLOcWm_TR_BWa_U&part=snippet&id=${video.youtubeLink}`,
              )
              .then((resp) => {
                rst.push({
                  id: video.id,
                  title: resp.data.items[0].snippet.title,
                  link: resp.data.items[0].id,
                });
              });
          });
          commit('requestSuccess', rst);
          resolve(rst);
        },
        (errors: any) => {
          commit('updateStatus', errors);
          reject();
        },
      );
    });
  },
  newLink: ({ commit, dispatch }: { commit: any; dispatch: any }, video: VideoLink) => {
    videoService.create(video).subscribe(
      (result: any) => {
        commit('requestSuccess', result);
        store.dispatch('videos/linkRequest');
      },
      (errors: any) => {
        commit('updateStatus', errors);
      },
    );
  },
  deleteLink: ({ commit, dispatch }: { commit: any; dispatch: any }, video: VideoLink) => {
    videoService.delete(video).subscribe(
      (result: any) => {
        commit('requestSuccess', result);
        store.dispatch('videos/linkRequest');
      },
      (errors: any) => {
        commit('updateStatus', errors);
      },
    );
  },
};

// tslint:disable-next-line:typedef
const mutations: any = {
  requestSuccess: (userState: any, payload: any) => {
    userState.Content = payload;
  },
  updateStatus: (userState: any, payload: string) => {
    userState.status = payload;
    EventBus.$emit('Alert', payload, payload.indexOf('Successful') !== -1 ? 'info' : 'error');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
