var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.imageDialog),callback:function ($$v) {_vm.imageDialog=$$v},expression:"imageDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-layout',[_c('v-flex',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-row',[_c('v-col',[_c('v-select',{staticStyle:{"margin-bottom":"-30px","max-width":"250px"},style:(_vm.$vuetify.breakpoint.mdAndDown ? 'width: 100vw;' : 'width: 60vw;'),attrs:{"items":_vm.artTypesString,"label":_vm.selectedArtType,"persistent-hint":"","hint":"Art Type"},model:{value:(_vm.selectedArtType),callback:function ($$v) {_vm.selectedArtType=$$v},expression:"selectedArtType"}})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.modalArt.title),callback:function ($$v) {_vm.$set(_vm.modalArt, "title", $$v)},expression:"modalArt.title"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('v-switch',{attrs:{"label":"Puchase at Gallery"},model:{value:(_vm.modalArt.purchaseAtGallery),callback:function ($$v) {_vm.$set(_vm.modalArt, "purchaseAtGallery", $$v)},expression:"modalArt.purchaseAtGallery"}})],1),_vm._v(" "),(!_vm.modalArt.purchaseAtGallery)?_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Price","rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Price","required":""},model:{value:(_vm.modalArt.price),callback:function ($$v) {_vm.$set(_vm.modalArt, "price", $$v)},expression:"modalArt.price"}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),(_vm.modalArt.purchaseAtGallery)?_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Gallery","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Gallery","required":""},model:{value:(_vm.modalArt.gallery),callback:function ($$v) {_vm.$set(_vm.modalArt, "gallery", $$v)},expression:"modalArt.gallery"}})]}}],null,true)})],1):_vm._e()],1),_vm._v(" "),(!_vm.showJewlleryOptions)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Description","error-messages":errors},model:{value:(_vm.modalArt.description),callback:function ($$v) {_vm.$set(_vm.modalArt, "description", $$v)},expression:"modalArt.description"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),(!_vm.showJewlleryOptions)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Dimensions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Art Dimensions","error-messages":errors},model:{value:(_vm.modalArt.dimensions),callback:function ($$v) {_vm.$set(_vm.modalArt, "dimensions", $$v)},expression:"modalArt.dimensions"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),(_vm.isFinished && _vm.showStandingArtShipping)?_c('v-row',[_c('v-subheader',{staticStyle:{"position":"absolute","margin-top":"-12px","margin-left":"-5px"}},[_vm._v("Shipping Dimensions")]),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Pounds","rules":{ regex: /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Pounds","error-messages":errors},model:{value:(_vm.dimensions.pounds),callback:function ($$v) {_vm.$set(_vm.dimensions, "pounds", $$v)},expression:"dimensions.pounds"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Ounces","rules":{ regex: /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ounces","error-messages":errors},model:{value:(_vm.dimensions.ounces),callback:function ($$v) {_vm.$set(_vm.dimensions, "ounces", $$v)},expression:"dimensions.ounces"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Width","rules":{ regex: /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Width (Inches)","error-messages":errors},model:{value:(_vm.dimensions.width),callback:function ($$v) {_vm.$set(_vm.dimensions, "width", $$v)},expression:"dimensions.width"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Length","rules":{ regex: /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Length (Inches)","error-messages":errors},model:{value:(_vm.dimensions.length),callback:function ($$v) {_vm.$set(_vm.dimensions, "length", $$v)},expression:"dimensions.length"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Height","rules":{ regex: /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Height (Inches)","error-messages":errors},model:{value:(_vm.dimensions.height),callback:function ($$v) {_vm.$set(_vm.dimensions, "height", $$v)},expression:"dimensions.height"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),(_vm.isFinished && _vm.showTrayShipping)?_c('v-row',[_c('v-select',{staticStyle:{"margin":"0 10px"},attrs:{"items":_vm.traySizesString,"label":_vm.selectedTraySize,"persistent-hint":"","hint":"Shipping size"},model:{value:(_vm.selectedTraySize),callback:function ($$v) {_vm.selectedTraySize=$$v},expression:"selectedTraySize"}})],1):_vm._e(),_vm._v(" "),(_vm.showJewlleryOptions)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Color","error-messages":errors},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Size","error-messages":errors},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('v-select',{attrs:{"items":_vm.chainLengthsString,"label":"Chain Length","error-messages":_vm.errors},model:{value:(_vm.chainLength),callback:function ($$v) {_vm.chainLength=$$v},expression:"chainLength"}})],1)],1):_vm._e(),_vm._v(" "),_c('ImageUpload',{ref:"imageUploader",attrs:{"art":_vm.modalArt}}),_vm._v(" "),_c('v-col',[(_vm.edit && _vm.isFinished)?_c('v-btn',{staticClass:"standard-btn",staticStyle:{"margin-top":"10px"},attrs:{"outlined":"","color":"#8bc34a","disabled":_vm.savingArt},on:{"click":function($event){return _vm.moveToSold()}}},[_vm._v("\n                    Move To Sold Work\n                    "),_c('v-icon',[_vm._v("keyboard_arrow_right")])],1):_vm._e()],1),_vm._v(" "),_c('v-layout',{attrs:{"justify-center":""}},[(_vm.serverErrorMessage != '')?_c('v-alert',{attrs:{"border":"right","colored-border":"","type":"error","elevation":"2"}},[_vm._v("\n                    "+_vm._s(_vm.serverErrorMessage)+"\n                  ")]):_vm._e()],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"standard-btn",staticStyle:{"margin-right":"20px"},attrs:{"outlined":"","color":"black","disabled":_vm.savingArt},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{staticClass:"standard-btn",attrs:{"outlined":"","color":"#8bc34a","disabled":_vm.savingArt},on:{"click":function($event){return _vm.saveItem()}}},[_vm._v("Save")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }