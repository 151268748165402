import store from '@/store/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import router from '@/router';
import dayjs from 'dayjs';

@Component({
    computed: mapGetters({
        calendarData: 'calendar/CalendarEntries',
    }),
})

export default class CalendarContainer extends Vue {
    private start: any = null;
    private end: any = null;
    private selectedEvent: object = {};
    private selectedElement: any = null;
    private selectedOpen: boolean = false;
    private currDate = new Date();
    private today: string = `${this.currDate.getFullYear()}-${('0' + (this.currDate.getMonth() + 1)).slice(-2)}-${('0' + this.currDate.getDate()).slice(-2)}`;
    private focus: string = this.today;
    private loadingCalendar: boolean = true;
    private type: string = 'month';
    private typeToLabel: object = {
        'month': 'Month',
        'week': 'Week',
        'day': 'Day',
        '4day': '4 Days',
    };


    private get calendarInstance(): Vue & {
        prev: () => void, next: () => void,
        getFormatter: (format: any) => any;
    } {
        return this.$refs.calendar as Vue & { prev: () => void, next: () => void, getFormatter: (format: any) => any };
    }

    private created() {
        store.dispatch('calendar/entryRequest').then(() => {
            setTimeout(() => this.loadingCalendar = false, 1000);
        });
    }

    private title() {
        const { start, end } = this;
        if (!start || !end) {
            return '';
        }

        const startMonth = this.monthFormatter(start);
        const endMonth = this.monthFormatter(end);
        const suffixMonth = startMonth === endMonth ? '' : endMonth;

        const startYear = start.year;
        const endYear = end.year;
        const suffixYear = startYear === endYear ? '' : endYear;

        const startDay = start.day + this.nth(start.day);
        const endDay = end.day + this.nth(end.day);

        switch (this.type) {
            case 'month':
                return `${startMonth} ${startYear}`;
            case 'week':
            case '4day':
                return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
            case 'day':
                return `${startMonth} ${startDay} ${startYear}`;
        }
        return '';
    }

    private getEventColor(event: any) {
        return event.color;
    }

    private get monthFormatter(): any {
        return this.calendarInstance.getFormatter({
            timeZone: 'UTC', month: 'long',
        });
    }

    private viewDay({ date }) {
        this.focus = date;
        this.type = 'day';
    }

    private setToday() {
        this.focus = this.today;
    }

    private prev(): void {
        this.calendarInstance.prev();
    }

    private next(): void {
        this.calendarInstance.next();
    }

    private isGroupEvent(event) {
        return event.name === 'Group Class Opening';
    }

    private showEvent({ nativeEvent, event }) {
        const open = () => {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            setTimeout(() => this.selectedOpen = true, 10);
        };

        if (this.selectedOpen) {
            this.selectedOpen = false;
            setTimeout(open, 10);
        } else {
            open();
        }
    }
    private updateRange({ start, end }) {
        this.start = start;
        this.end = end;
    }
    private nth(d: any) {
        return d > 3 && d < 21
            ? 'th'
            : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
    }

    private goToGroupForm(selectedDay) {
        store.dispatch('calendar/UpdateGroupEvent', selectedDay.start).then(() => {
            router.push('GroupEventForm');
        });
    }

    private formatDate(startDate: string, endDate: string) {
       if (startDate !== undefined && endDate !== undefined && startDate !== null && endDate !== null) {
        const start = dayjs(startDate);
        const end = dayjs(endDate);
        return start.format('MMMM D') + ' ' + start.format('h:mm A') + ' - ' + end.format('h:mm A');
       }

    }
}
