import { mapGetters } from 'vuex';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import store from '@/store/store';
import router from '@/router';
import { EventBus } from '@/event-bus';

@Component({
  computed: mapGetters({
    pages: 'pages/content',
    isAuthenticated: 'auth/isAuthenticated',
    paymentEnabled: 'toggles/payment',
    classesEnabled: 'toggles/classes',
    eventsEnabled: 'toggles/events',
    cartSize: 'cart/numberOfItemsInCart',
  }),
})

// tslint:disable-next-line:class-name
export default class ArtNav extends Vue {
  // Getters
  private classesEnabled!: boolean;
  private eventsEnabled!: boolean;

  private bDrawer: boolean = false;
  private loading: boolean = true;

  private mounted() {
    this.$store.dispatch('pages/pagesRequest').then(() => (this.loading = false));
  }

  private openAdminTools(): void {
    EventBus.$emit('OpenAdminTools', null);
  }

  private openDrawer() {
    this.bDrawer = !this.bDrawer;
  }

  private setHeaderSize() {
    return this.$vuetify.breakpoint.smAndDown ? 'font-size: 18px;' : 'font-size: 32px';
  }

  private filterPages(pages: []) {
    if (this.loading) {
      return Array(9).fill('');
    }
    return pages.filter((item: any) => {
      if (!this.classesEnabled && item.id === 'Classes') {
        return false;
      }
      if (!this.eventsEnabled && item.id === 'Events') {
        return false;
      }
      return true;
    });
  }
}
