import CalendarContainer from '@/components/calendar/calendar';
import { Class } from '@/models/events/class.interface';
import EventTypes from '@/models/events/event.enum';
import { EventPackage } from '@/models/events/eventPackage.interface';
import { Gallery } from '@/models/events/gallery.interface';
import { Show } from '@/models/events/show.interface';
import store from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters({
    galleries: 'events/galleries',
    shows: 'events/shows',
    classes: 'events/classes',
    page: 'pages/eventsPage',
    add: 'auth/Add',
    edit: 'auth/Edit',
    deleteEvent: 'auth/Delete',
    eventsEnabled: 'toggles/events',
  }),
})
export default class Events extends Vue {
  // Getters
  private eventsEnabled!: boolean;
  private edit!: boolean;
  private add!: boolean;
  private shows!: any;

  private monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  private daysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  private Events = EventTypes;
  private gallery: Gallery = new Gallery();
  private show: Show = new Show();
  private artClass: Class = new Class();
  private items = [EventTypes.Gallery, EventTypes.Class, EventTypes.Show];

  private dialog: boolean = false;
  private eventType: string = '';
  private formTitle: string = '';
  private daysPicked = [];
  private choosenDate = '';
  private choosenTime = '';
  private choosenEndDate = '';
  private choosenEndTime = '';

  private mounted(): void {
    if (!this.eventsEnabled) {
      this.$router.replace('/');
    }
    if (store.getters['auth/isAuthenticated']) {
      store.watch(
        () => this.add,
        (rst) => {
          if (this.dialog === false && rst === true) {
            this.dialog = true;
          }
        },
      );
    }
    store.dispatch('events/contentRequest');
  }

  private editEvent(event: any, type: string): void {
    switch (type) {
      case EventTypes.Gallery:
        this.eventType = EventTypes.Gallery;
        this.gallery = event as Gallery;
        break;
      case EventTypes.Show:
        this.eventType = EventTypes.Show;
        this.show = event as Show;
        const dateTime = this.show.date.split('T');
        const dateTimeEnd = this.show.endDate.split('T');
        this.choosenDate = dateTime[0];
        this.choosenTime = dateTime[1];
        this.choosenEndDate = dateTimeEnd[0];
        this.choosenEndTime = dateTimeEnd[1];
        break;
      case EventTypes.Class:
        this.eventType = EventTypes.Class;
        this.artClass = event as Class;
        break;
    }
    this.dialog = true;
  }

  private deleteEventHandler(event: any, type: EventTypes): void {
    store.dispatch('events/deleteEvent', new EventPackage(type, event));
  }

  private saveItem(): void {
    let key: string = 'new';
    let eventPackage = {};

    if (this.edit) {
      key = 'update';
    }

    switch (this.eventType) {
      case EventTypes.Gallery:
        eventPackage = new EventPackage(this.eventType, this.gallery);
        break;
      case EventTypes.Show:
        eventPackage = new EventPackage(this.eventType, this.show);
        ((eventPackage as EventPackage).event as Show).date = this.choosenDate + 'T' + this.choosenTime;
        ((eventPackage as EventPackage).event as Show).endDate = this.choosenEndDate + 'T' + this.choosenEndTime;
        break;
      case EventTypes.Class:
        this.artClass.daysTaught = this.daysPicked.join(',');
        eventPackage = new EventPackage(this.eventType, this.artClass);
        break;
    }

    store.dispatch('events/' + key + 'Event', eventPackage).then(() => {
      if (this.add) {
        store.dispatch('auth/adminReset');
      } else {
        this.dialog = false;
      }
    });
  }

  private closeDialog(): void {
    store.dispatch('auth/adminReset');
    this.dialog = false;
  }

  private splitDays(days: string): string[] {
    return days !== null ? days.split(',') : [];
  }

  //#region Month Handling
  private getMonthName(index: number): string {
    return this.monthList[new Date(this.shows[index].date).getMonth()];
  }

  private showDate(index: number): boolean {
    if (index !== 0) {
      const lastMonth = new Date(this.shows[index - 1].date).getMonth();
      const currMonth = new Date(this.shows[index].date).getMonth();
      if (lastMonth === currMonth) {
        return false;
      }
    }
    return true;
  }

  private formatPhone(phone: string): string {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
  }
  //#endregion
}
