export interface IGallery {
    id: string | undefined;
    name: string;
    address: string;
    phone: string;
}

export class Gallery implements IGallery {
    public id: string | undefined;
    public name: string;
    public address: string;
    public phone: string;

    constructor() {
        this.name = '';
        this.address = '';
        this.phone = '';
    }
}
