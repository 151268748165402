import CalendarContainer from '@/components/calendar/calendar';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import store from '@/store/store';

@Component({
  computed: mapGetters({
    classes: 'toggles/classes',
  }),
  components: {
    CalendarContainer,
  },
})
export default class Calendar extends Vue {
  private classes!: boolean;

  private mounted(): void {
    store.dispatch('events/contentRequest');
    if (!this.classes) {
      this.$router.replace('/');
    }
  }
}
