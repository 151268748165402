import { ICredentials } from '../../models/credentials.interface';
import { EventBus } from '../../event-bus';
import { authService } from '../../services/auth.services';
import store from '../store';

const state: any = {
  token: localStorage.getItem('auth-token') || '',
  status: '',
  AdminActions: {
    Edit: false,
    Delete: false,
    Add: false,
  },
};

const getters: any = {
  isAuthenticated: (authState: any) => !!authState.token,
  authStatus: (authState: any) => authState.status,
  authToken: (authState: any) => authState.token,
  Edit: (userState: any) => userState.AdminActions.Edit,
  Delete: (userState: any) => userState.AdminActions.Delete,
  Add: (userState: any) => userState.AdminActions.Add,
};

const actions: any = {
  authRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, credentials: ICredentials) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.login(credentials).subscribe(
        (result: any) => {
          localStorage.setItem('auth-token', result);
          commit('authSuccess', result);
          EventBus.$emit('logged-in', null);
          resolve(result);
        },
        (errors: any) => {
          commit('authError', errors);
          localStorage.removeItem('auth-token');
          reject(errors);
        },
      );
    });
  },
  authLogout: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    return new Promise((resolve, reject) => {
      commit('authLogout');
      localStorage.removeItem('auth-token');
      resolve();
    });
  },
  clearCache: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    authService.clearCache();
    EventBus.$emit('ReloadWithMessage', `Clear cache successful`);
  },
  checkAuth: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    return new Promise((resolve, reject) => authService.ping());
  },
  adminEdit: ({ commit, dispatch }: { commit: any; dispatch: any }) => commit('adminEdit'),
  adminDelete: ({ commit, dispatch }: { commit: any; dispatch: any }) => commit('adminDelete'),
  adminAdd: ({ commit, dispatch }: { commit: any; dispatch: any }) => commit('adminAdd'),
  adminReset: ({ commit, dispatch }: { commit: any; dispatch: any }) => commit('adminReset'),
};

// tslint:disable-next-line:typedef
const mutations = {
  authRequest: (authState: any) => {
    authState.status = 'attempting authentication request';
  },
  authSuccess: (authState: any, authToken: string) => {
    authState.status = 'authentication succeeded';
    authState.token = authToken;
  },
  authError: (authState: any) => {
    authState.status = 'error';
  },
  authLogout: (authState: any) => {
    authState.token = '';
  },
  adminEdit: (autState: any) => {
    autState.AdminActions.Edit = true;
    autState.AdminActions.Delete = false;
    autState.AdminActions.Add = false;
  },
  adminDelete: (autState: any) => {
    autState.AdminActions.Edit = false;
    autState.AdminActions.Delete = true;
    autState.AdminActions.Add = false;
  },
  adminAdd: (autState: any) => {
    autState.AdminActions.Edit = false;
    autState.AdminActions.Delete = false;
    autState.AdminActions.Add = true;
  },
  adminReset: (autState: any) => {
    autState.AdminActions.Add = false;
    autState.AdminActions.Edit = false;
    autState.AdminActions.Delete = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
