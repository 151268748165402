export interface IVideoLink {
    id: string | undefined;
    youtubeLink: string;
}

export class VideoLink implements IVideoLink {
    public id: string | undefined;
    public youtubeLink: string;

    constructor(link: string) {
        this.youtubeLink = link;
    }
}
