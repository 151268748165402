import Vue from 'vue';
import Vuex from 'vuex';
import pages from './modules/pages';
import auth from './modules/auth';
import art from './modules/art';
import events from './modules/event';
import videos from './modules/video';
import calendar from './modules/calendar';
import toggles from './modules/toggles';
import cart from './modules/cart';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    pages: {
      namespaced: true,
      state: pages.state,
      mutations: pages.mutations,
      getters: pages.getters,
      actions: pages.actions,
    },
    auth: {
      namespaced: true,
      state: auth.state,
      mutations: auth.mutations,
      getters: auth.getters,
      actions: auth.actions,
    },
    art: {
      namespaced: true,
      state: art.state,
      mutations: art.mutations,
      getters: art.getters,
      actions: art.actions,
    },
    events: {
      namespaced: true,
      state: events.state,
      mutations: events.mutations,
      getters: events.getters,
      actions: events.actions,
    },
    videos: {
      namespaced: true,
      state: videos.state,
      mutations: videos.mutations,
      getters: videos.getters,
      actions: videos.actions,
    },
    calendar: {
      namespaced: true,
      state: calendar.state,
      mutations: calendar.mutations,
      getters: calendar.getters,
      actions: calendar.actions,
    },
    toggles: {
      namespaced: true,
      state: toggles.state,
      mutations: toggles.mutations,
      getters: toggles.getters,
      actions: toggles.actions,
    },
    cart: {
      namespaced: true,
      state: cart.state,
      mutations: cart.mutations,
      getters: cart.getters,
      actions: cart.actions,
    },
  },
});
