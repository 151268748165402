export interface IRequest {
  PageNumber: number;
  PageName: PageKeys;
  Order: boolean;
  ArtType: ArtType;
}

export enum PageKeys {
  Store = 'Store',
  Sold = 'Sold',
  About = 'About',
  Classes = 'Classes',
  Events = 'Events',
  Cart = 'Cart',
}

export enum ArtType {
  Standing = 0,
  Trays = 1,
  Jewelry = 2,
  Lighting = 3,
  Mixed = 4,
}


export enum TraySizes {
  Square,
  Small,
  Medium,
  Large,
}


export enum ChainLengths {
  SeventeenAndEighteen = '17" and 18"',
  Eighteen = '18"',
}
