import { mapGetters } from 'vuex';
import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { IArt, Art } from '@/models/art.interface';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import { ArtImage } from '@/models/image.interface';
import store from '@/store/store';
import GalleryImageEditor from '@/components/modals/gallery/galleryImageEditor';
import { EventBus } from '@/event-bus';
import router from '@/router';
import { ArtType, PageKeys } from '@/models/request.interface';
import { Page } from '@/models/page.interface';

const AppProps = Vue.extend({
  props: {
    pageRequest: Object,
    page: Object,
    isFinished: Boolean,
  },
});

@Component({
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
    edit: 'auth/Edit',
    add: 'auth/Add',
    deleteArt: 'auth/Delete',
    loadingArt: 'art/IsLoading',
    paymentEnabled: 'toggles/payment',
    cartItems: 'cart/cartItems',
  }),
  components: {
    vueCustomScrollbar,
    GalleryImageEditor,
  },
})
export default class Gallery extends AppProps {
  // Getters
  private isAuthenticated!: boolean;
  private edit!: boolean;
  private add!: boolean;

  // Art variables
  private editedItem: IArt = new Art(this.isFinished ? PageKeys.Store : PageKeys.Sold);
  private art = [];
  // Cart
  private addingItemToCart: boolean = false;
  // Infinite scrolling
  private bottom: boolean = false;
  private settings: object = {
    maxScrollbarLength: 60,
  };
  // Modal triggers
  private adminDialog: boolean = false;

  // Pagination
  private currentPageIndex: number = 1;
  private loadingImages: boolean = false;

  // Art type
  private selectedArtType = ArtType[ArtType.Standing];
  private artTypesString = [
    { text: 'Standing Art', value: 'Standing' },
    { text: 'Mixed', value: 'Mixed' },
    { text: 'Trays', value: 'Trays' },
    { text: 'Jewelry', value: 'Jewelry' },
    { text: 'Lighting', value: 'Lighting' },
  ];

  private created(): void {
    if (this.$route.params.type !== undefined) {
      this.selectedArtType = this.$route.params.type;
    }
    if (this.$route.params.page !== undefined) {
      this.currentPageIndex = Number(this.$route.params.page);
    }


    if (this.isAuthenticated) {
      store.dispatch('auth/adminReset');
      store.watch(
        () => this.add,
        (rst) => {
          if (rst === true && this.adminDialog === false) {
            this.editedItem = new Art(this.isFinished ? PageKeys.Store : PageKeys.Sold);
            this.adminDialog = true;
          }
        },
      );
      EventBus.$on('CloseGalleryImageEditor', () => {
        this.adminDialog = false;
      });
    }
    if (this.art.length === 0) {
      this.art = store.getters['art/PageWithArtType'](this.pageRequest.PageName, ArtType[this.selectedArtType]).Content;
    }
  }

  @Watch('selectedArtType')
  private changeArtType(newValue: string, oldValue: string) {
    this.loadingImages = true;
    this.pageRequest.PageNumber = this.currentPageIndex = 1;
    this.art = store.getters['art/PageWithArtType'](this.pageRequest.PageName, ArtType[newValue]).Content;
    this.loadingImages = false;
    this.updateRoute();
  }

  @Watch('currentPageIndex')
  private loadMore(newValue: number, oldValue: number) {
    this.loadingImages = true;
    this.pageRequest.PageNumber = newValue;
    this.loadingImages = false;
    this.$route.params.page = newValue.toString();
    this.updateRoute();
  }

  private updateRoute() {
    this.$router.replace({
      path: `/${this.isFinished ? PageKeys.Store : PageKeys.Sold}/${this.selectedArtType}/${this.currentPageIndex}`,
    }).catch((err) => {
      // Weird NavigationDuplicated error being thrown: https://github.com/vuejs/vue-router/issues/2872
    });
  }

  get artCollection() {
    if (this.art.length === 0) {
      return [];
    }
    const start = (this.currentPageIndex - 1) * 10;
    const end = this.art.length < start + 10 ? this.art.length : start + 10;
    return this.art.slice(start, end);
  }

  get paginationLength() {
    return Math.ceil(
      store.getters['art/PageWithArtType'](this.pageRequest.PageName, ArtType[this.selectedArtType]).Content.length /
        10,
    );
  }

  private openModal(item: IArt) {
    router.push({
      name: 'Art', params: {
        state: this.pageRequest.PageName,
        type: Object.keys(ArtType).find((key) => ArtType[key] === item.artType)!,
        artId: item.id!,
      },
    });
  }

  private editEntry(item: IArt) {
    this.editedItem = item;
    this.editedItem.artImage = item.artImage;
    this.adminDialog = true;
  }

  private createEntry() {
    this.adminDialog = true;
  }

  private deleteArtEntry(item: IArt) {
    store.dispatch('art/deleteRequest', {
      id: item.id,
      page: this.isFinished ? PageKeys.Store : PageKeys.Sold,
    });
  }

  private getLayoutForGallery() {
    return this.$vuetify.breakpoint.smAndDown ? 'column' : 'row wrap';
  }
}
