import Vue from 'vue';
import Router from 'vue-router';
import About from './views/about/about.vue';
import Login from './views/login/login';
import Store from './views/finished/finished';
import Archived from './views/archived/archived';
import Events from './views/events/events';
import Classes from './views/classes/classes';
import Groups from './views/forms/groups/groups';
import Checkout from './views/checkout/checkout';
import ArtPiece from './views/artPiece/artPiece';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Store,
      redirect: '/store',
    },
    {
      path: '/about',
      name: 'About',
      component: About,
    },
    {
      path: '/store/:type?/:page?',
      name: 'Store',
      component: Store,
    },
    {
      path: '/sold/:type?/:page?',
      name: 'Sold',
      component: Archived,
    },
    {
      path: '/events',
      name: 'Events',
      component: Events,
    },
    {
      path: '/classes',
      name: 'Classes',
      component: Classes,
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Login,
    },
    {
      path: '/groupEventForm',
      name: 'GroupEventForm',
      component: Groups,
    },
    {
      path: '/Checkout',
      name: 'Checkout',
      component: Checkout,
    },
    {
      path: '/art/:type/:state/:artId',
      name: 'Art',
      component: ArtPiece,
    },
  ],
});

export default router;
