<template>
  <v-app>
    <ArtNav></ArtNav>
    <v-container>
      <router-view></router-view>
      <Admin v-if="isAuthenticated"></Admin>
    </v-container>
    <v-snackbar
      v-bind="snackbarCentered ? { [`centered`]: true } : { [`right`]: true }"
      v-model="snackbar"
      :color="color"
      v-if="isAuthenticated"
    >
      <v-layout justify-center align-center v-if="!snackbarCentered">
        <v-row justify="space-around" align="center">
          {{ text }}
          <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-row>
      </v-layout>
      <v-col v-if="snackbarCentered" justify-content="center" align-self="center">
        <v-row justify="center"
          ><div class="h2" style="margin-bottom: 20px">{{ text }}</div></v-row
        >
        <v-row justify="center">
          <v-progress-circular indeterminate color="gray"></v-progress-circular>
        </v-row>
      </v-col>
    </v-snackbar>
  </v-app>
</template>

<script>
import ArtNav from './components/nav/navBar';
import Admin from '@/components/admin/admin';
import router from '@/router';
import { EventBus } from '@/event-bus';
import { mapGetters } from 'vuex';
import { PageKeys } from './models/request.interface';

export default {
  name: 'App',
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
  }),
  components: {
    ArtNav,
    Admin,
  },
  data() {
    return {
      text: '',
      snackbar: '',
      snackbarCentered: false,
      countDown: 'Counting down',
      color: '',
      pageRequest: {
        PageName: PageKeys.Store,
        PageNumber: 1,
        Order: true,
      },
    };
  },
  mounted() {
    this.$store.dispatch('toggles/togglesRequest');

    EventBus.$on('Alert', (message, level) => {
      switch (level) {
        case 'info':
          this.color = 'blue';
          break;
        case 'error':
          this.color = 'red';
          break;
      }
      this.text = message;
      this.snackbar = true;
    });

    EventBus.$on('ReloadWithMessage', (message) => {
      this.text = message;
      this.snackbar = true;
      this.snackbarCentered = true;
      setTimeout(() => location.reload(), 2000);
    });

    EventBus.$on('Logout', () => {
      this.$store.dispatch('auth/authLogout').then(() => {
        router.push('/Auth');
      });
    });

    if (this.$store.getters['auth/isAuthenticated']) {
      setTimeout(() => this.$store.dispatch('auth/checkAuth'), 2000);
    }
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Alegreya+SC|Calligraffitti|Maven+Pro');

#app {
  background-color: #E0DBD3;
}

body {
  background-color: #E0DBD3;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-font,
p {
  font-family: 'Maven Pro', sans-serif !important;
}

.header-font {
  font-family: 'Calligraffitti', cursive !important;
  padding: 25px;
  font-size: 2.25rem;
}

.subheader-font {
  font-family: 'Calligraffitti', cursive !important;
  padding: 0px;
  padding-left: 15px;
  font-size: 1.8rem;
}

.notes-font {
  font-family: 'Alegreya SC', serif !important;
}

.header {
  text-shadow: 1px 1px black;
}
.body-links {
  color: #5f8852 !important;
  text-decoration: none;
}

.grey.lighten-2 {
  background-color: white !important;
}

.standard-btn {
  min-width: 100px !important;
}

.v-application .grey.lighten-2 {
  background-color: white !important;
}

.v-stepper__wrapper {
  height: 100% !important;
}
</style>
