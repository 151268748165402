import axios from 'axios';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Rx';

class CalendarService extends BaseService {

    private static instance: CalendarService;

    private constructor() { super(); }

    public static get Instance(): CalendarService {
        return this.instance || (this.instance = new this());
    }

    public get(): Observable<any> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/calendar`))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }

    public sendEmail(emailModel: any): Observable<any> {
        return Observable.fromPromise(axios.post(`${BaseService.api}/Calendar/groupEmail`, emailModel))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
}

export const calendarService: CalendarService = CalendarService.Instance;
