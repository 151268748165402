import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { required, email, max, numeric } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode, validate } from 'vee-validate';
import { RefsExtType } from '@/models/refs.interface';
import Header from '@/components/common/header.vue';
import { IArt } from '@/models/art.interface';
import { IImage } from '@/models/image.interface';
import { ArtType, PageKeys } from '@/models/request.interface';
import { EventBus } from '@/event-bus';
import store from '@/store/store';
import { artService } from '@/services/art.service';

extend('required', {
  ...required,
  message: '{_field_} cannot be empty',
});

extend('numeric', {
  ...numeric,
  message: '{_field_} must be numeric',
});

extend('length', {
  validate(value, { length }) {
    return Number(value.length) === Number(length);
  },
  params: ['length'],
  message: 'The {_field_} field must be {length} numbers',
});

@Component({
  computed: mapGetters({
    art: 'art/Page',
  }),
  components: {
    Header,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Checkout extends Vue {
  public $refs!: {
    observer: RefsExtType;
    imageUploader: RefsExtType;
  };
  // Getters
  private art!: any;
  private cart: IArt[] = [];

  private loading: boolean = true;
  private emptyCart: boolean = false;

  private currentStep: number = 1;

  private enlargeImage: boolean = false;
  private modalArtId: string = '';

  private puchaseBtnDisabled: boolean = false;

  private baseCost: number = 0;
  private totalCost: number = 0;
  private shippingCost: number = 0;
  private insuranceCost: number = 0;

  private insuranceHover: boolean = false;
  private artIds: string[] | undefined;

  private finishPurchaseWorkflow: boolean = true;
  private pickUp: boolean = false;

  private mounted() {
    // tslint:disable-next-line:no-string-literal
    const checkoutId = this.getUrlVars()['checkoutId'];
    this.finishPurchaseWorkflow = checkoutId !== null && checkoutId !== undefined;
    if (this.finishPurchaseWorkflow) {
      store.dispatch('cart/moveArtAfterPurchase', checkoutId);
    }
    this.calculateCart();
  }

  get getTotalCost() {
    if (this.pickUp) {
      return this.baseCost;
    }
    return this.totalCost;
  }

  private getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => (vars[key] = value));
    return vars;
  }

  private calculateCart() {
    store.dispatch('cart/checkCart').then((rst) => {
      if (rst !== undefined && rst.art.length !== 0) {
        this.cart = rst.art;
        this.baseCost = rst.baseTotal;
        this.shippingCost = rst.shippingCost;
        this.insuranceCost = rst.shippingInsuranceCost;
        this.totalCost = rst.totalCost;
      } else {
        this.emptyCart = this.cart.length === 0;
      }
      this.loading = false;
    }).catch(() => {
      this.emptyCart = this.cart.length === 0;
    });
  }

  private viewArtImages(id: string) {
    this.modalArtId = id;
    this.enlargeImage = true;
  }

  private getArtContentFromId() {
    if (this.modalArtId === '') {
      return [];
    }
    const choosenArt = this.cart.filter((λ: any) => λ.id === this.modalArtId)[0];
    return [
      {
        content: choosenArt.artImage.content,
        thumbnail: choosenArt.artImage.thumbnail,
      },
    ].concat(choosenArt.detailedImages);
  }

  private artInCart() {
    return this.cart;
  }

  private removeFromCart(id: string) {
    this.$store.dispatch('cart/removeFromCart', id).then(() => {
      this.emptyCart = this.cart.length === 0;
      this.calculateCart();
    });
  }

  private purchase() {
    this.puchaseBtnDisabled = false;
    store.dispatch('cart/purchaseArt', this.pickUp).then((rst) => {
      location.replace(rst.checkout_page_url);
      this.puchaseBtnDisabled = true;
    });
  }
}
