import axios from 'axios';
import { ICredentials } from '../models/credentials.interface';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Rx';

class AuthService extends BaseService {
  private static instance: AuthService;

  private constructor() {
    super();
  }

  public static get Instance(): AuthService {
    return this.instance || (this.instance = new this());
  }

  public login(credentials: ICredentials): Observable<any> {
    return Observable.fromPromise(axios.post(`${BaseService.authApi}/auth/login`, credentials))
      .map((res: any) => this.checkResponse(res).auth_token)
      .catch((error: any) => this.handleError(error.response));
  }

  public clearCache(): Observable<any> {
    return Observable.fromPromise(axios.get(`${BaseService.api}/cache/DumpCache`))
      .map((res: any) => this.checkResponse(res))
      .catch((error: any) => this.handleError(error.response));
  }

  public ping(): Observable<any> {
    return Observable.fromPromise(axios.get(`${BaseService.authApi}/auth/ping`))
      .map((res: any) => this.checkResponse(res))
      .catch((error: any) => this.handleError(error.response));
  }
}

export const authService: AuthService = AuthService.Instance;
