import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ArtType, IRequest, PageKeys } from '@/models/request.interface';
import Gallery from '@/components/gallery/gallery';
import store from '@/store/store';
import Header from '@/components/common/header.vue';

@Component({
  computed: mapGetters({
    art: 'art/Page',
    page: 'pages/soldPage',
  }),
  components: {
    Gallery,
    Header,
  },
})
export default class Current extends Vue {
  // Getters
  private art!: any;

  private loading: boolean = true;
  private pageRequest: IRequest = {
    ArtType: ArtType.Standing,
    PageName: PageKeys.Sold,
    PageNumber: 1,
    Order: true,
  };

  private created(): void {
    const art = this.art(this.pageRequest.PageName).Content;
    this.loading = art.length === 0;
    if (art.length === 0) {
      store.dispatch('art/contentRequest', this.pageRequest).then(() => (this.loading = false));
    }
  }
}
