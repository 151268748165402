import { mapGetters } from 'vuex';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import store from '@/store/store';
import { EventBus } from '@/event-bus';

const AppProps = Vue.extend({
  props: {
    page: String,
  },
  directives: {
    infiniteScroll: require('vue-infinite-scroll'),
  },
});

@Component({
  computed: mapGetters({
    updatingToggles: 'toggles/updating',
    isAuthenticated: 'auth/isAuthenticated',
    payment: 'toggles/payment',
    classes: 'toggles/classes',
    events: 'toggles/events',
    editing: 'auth/Edit',
    deleting: 'auth/Delete',
    adding: 'auth/Add',
  }),
})
export default class Admin extends AppProps {
  // Getters
  private payment!: boolean;
  private classes!: boolean;
  private events!: boolean;

  private editing!: boolean;
  private adding!: boolean;
  private deleting!: boolean;

  private sheet: boolean = false;
  private temp: boolean = true;

  private addDisallowList: string[] = ['Current'];
  private deleteDisallowList: string[] = ['Current'];
  private editDisallowList: string[] = [];

  private mounted(): void {
    EventBus.$on('OpenAdminTools', (message, level) => {
      store.dispatch('toggles/togglesRequest').then(() => {
        this.sheet = true;
      });
    });
  }

  private adminDelete() {
    store.dispatch('auth/adminDelete');
    this.sheet = false;
  }
  private adminEdit() {
    store.dispatch('auth/adminEdit');
    this.sheet = false;
  }
  private adminAdd() {
    store.dispatch('auth/adminAdd');
    this.sheet = false;
  }
  private reset() {
    store.dispatch('auth/adminReset');
    this.sheet = false;
  }
  private clearCache() {
    store.dispatch('auth/clearCache');
    this.sheet = false;
  }

  private isAdminUpdatingContent() {
    return this.editing || this.adding || this.deleting;
  }

  private getUpdateType() {
    if (this.editing) {
      return 'Edit';
    } else if (this.adding) {
      return 'Add';
    } else if (this.deleting) {
      return 'Delete';
    }
    return 'Error';
  }

  private logout(): void {
    EventBus.$emit('Logout');
  }

  private updatePayment(payment: boolean): void {
    store.dispatch('toggles/togglesUpdate', {
      payment,
      classes: this.classes,
      events: this.events,
    });
  }

  private updateClasses(classes: boolean): void {
    store.dispatch('toggles/togglesUpdate', {
      payment: this.payment,
      classes,
      events: this.events,
    });
  }

  private updateEvents(events: boolean): void {
    store.dispatch('toggles/togglesUpdate', {
      payment: this.payment,
      classes: this.classes,
      events,
    });
  }

  private shouldShowAdd(): boolean {
    return !this.addDisallowList.includes(this.$route.name === null ? '' : this.$route.name as string);
  }

  private shouldShowEdit(): boolean {
    return !this.editDisallowList.includes(this.$route.name === null ? '' : this.$route.name as string);
  }

  private shouldShowDelete(): boolean {
    return !this.deleteDisallowList.includes(this.$route.name === null ? '' : this.$route.name as string);
  }
}
