import axios from 'axios';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Rx';

class PageService extends BaseService {

    private static instance: PageService;

    private constructor() { super(); }

    public static get Instance(): PageService {
        return this.instance || (this.instance = new this());
    }

    public get(): Observable<any> {
        return Observable.fromPromise(axios.get(`${BaseService.api}/pages`))
            .map((res: any) => res.data)
            .catch((error: any) => this.handleError(error.response));
    }
}

export const pageService: PageService = PageService.Instance;
