import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store/store';
import axios from 'axios';
import VueYouTubeEmbed from 'vue-youtube-embed';
import Vue from 'vue';
import Vuetify from 'vuetify';
import { ValidationProvider } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueYouTubeEmbed);
Vue.config.productionTip = false;

Vue.use(Vuetify);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

axios.interceptors.request.use(
  (config: any) => {
    const authToken: object = store.getters['auth/authToken'];
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (err: any) => {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('auth/authLogout').then(() => {
        router.push('/Auth');
      });
    }
    return error;
  },
);
