import { EventBus } from '@/event-bus';
import { ICalendarEntry } from '@/models/calendarEntry.interface';
import { calendarService } from '@/services/calendar.service';

const state = { content: [], status: '', groupEventDate: '', emailSent: false };

const getters: any = {
  CalendarEntries: (userState: any) => userState.content,
  UserChoosenDate: (userState: any) => userState.groupEventDate,
  EmailSent: (userState: any) => userState.emailSent,
};

const actions: any = {
  entryRequest: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    calendarService.get().subscribe(
      (result: ICalendarEntry) => {
        commit('requestSuccess', result);
      },
      (errors: any) => {
        commit('updateStatus', errors);
      },
    );
  },
  sendMailRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, emailModel: any) => {
    return new Promise((resolve, reject) => {
      calendarService.sendEmail(emailModel).subscribe(
        (result: any) => {
          commit('updateEmailState', result);
          if (result) {
            resolve();
          } else {
            reject();
          }
        },
        (errors: any) => {
          commit('updateEmailState', errors);
          reject();
        },
      );
    });
  },
  UpdateGroupEvent: ({ commit, dispatch }: { commit: any; dispatch: any }, groupEvent: any) => {
    commit('updateGroupEventDate', groupEvent);
  },
};

const mutations: any = {
  requestSuccess: (userState: any, payload: any) => {
    userState.content = payload;
  },
  updateStatus: (userState: any, payload: string) => {
    userState.status = payload;
    EventBus.$emit('Alert', payload, payload.indexOf('Successful') !== -1 ? 'info' : 'error');
  },
  updateGroupEventDate: (userState: any, payload: string) => {
    userState.groupEventDate = payload;
  },
  updateEmailState: (userState: any, payload: string) => {
    userState.emailSent = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
