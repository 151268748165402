import { pageService } from '../../services/page.service';

const state = { content: [], status: '' };

const getters: any = {
  content: (userState: any) => userState.content,
  aboutPage: (userState: any) => {
    let page = {};
    userState.content.forEach((element: any) => {
      if (element.pageRoute === 'About') {
        page = element;
      }
    });
    return page;
  },
  storePage: (userState: any) => {
    let page = {};
    userState.content.forEach((element: any) => {
      if (element.pageRoute === 'Store') {
        page = element;
      }
    });
    return page;
  },
  eventsPage: (userState: any) => {
    let page = {};
    userState.content.forEach((element: any) => {
      if (element.pageRoute === 'Events') {
        page = element;
      }
    });
    return page;
  },
  soldPage: (userState: any) => {
    let page = {};
    userState.content.forEach((element: any) => {
      if (element.pageRoute === 'Sold') {
        page = element;
      }
    });
    return page;
  },
};

const actions: any = {
  pagesRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, pages: any) => {
    return new Promise((resolve, reject) => {
      pageService.get().subscribe(
        (result: any) => {
          commit('pageSuccess', result);
          resolve();
        },
        (errors: any) => {
          commit('updateStatus', errors);
          reject();
        },
      );
    });
  },
};

// tslint:disable-next-line:typedef
const mutations: any = {
  pageSuccess: (userState: any, payload: any) => {
    userState.content = payload;
  },
  updateStatus: (userState: any, message: string) => {
    userState.status = message;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
