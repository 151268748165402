import { Observable } from 'rxjs';
import { EventBus } from '@/event-bus';

export abstract class BaseService {
  public static readonly api = process.env.VUE_APP_API;
  public static readonly authApi = process.env.VUE_APP_AUTH;

  protected handleError(error: any): Observable<never> {
    const applicationError: Headers = error.headers['Application-Error'];
    if (applicationError) {
      return Observable.throw(applicationError);
    }

    let modelStateErrors: any = '';

    if (error.data) {
      for (const key in error.data) {
        if (error.data[key]) {
          modelStateErrors += error.data[key] + '\n';
        }
      }
    }

    modelStateErrors = modelStateErrors === '' ? null : modelStateErrors;
    return Observable.throw(modelStateErrors || 'Server error');
  }

  protected checkResponse(response: any): any {
    if (response.status === 401) {
      EventBus.$emit('Logout');
    } else if (response.status !== 200) {
      throw response;
    } else {
      return response.data;
    }
  }
}
