import { toggleService } from '../../services/toggles.service';
import { EventBus } from '@/event-bus';

const state = {
  content: {},
  status: '',
  updating: false,
  updatingStatus: '',
  classes: false,
  payment: false,
  events: false,
};

const getters: any = {
  settings: (userState: any) => userState.content,
  payment: (userState: any) => userState.payment,
  classes: (userState: any) => userState.classes,
  events: (userState: any) => userState.events,
  updatingStatus: (userState: any) => userState.updatingStatus,
  updating: (userState: any) => userState.updating,
};

const actions: any = {
  togglesRequest: ({ commit, dispatch }: { commit: any; dispatch: any }, toggles: any) => {
    toggleService.get().subscribe(
      (result: any) => {
        commit('toggleSuccess', result);
      },
      (errors: any) => {
        commit('toggleStatus', errors);
      },
    );
  },
  togglesUpdate: ({ commit, dispatch }: { commit: any; dispatch: any }, toggles: any) => {
    commit('startUpdate');
    toggleService.update(toggles.payment, toggles.classes, toggles.events).subscribe(
      (result: any) => {
        commit('updatingStatus', {
          successful: true,
          payment: toggles.payment,
          classes: toggles.classes,
          events: toggles.events,
        });
      },
      (errors: any) => {
        commit('updatingStatus', {
          successful: false,
        });
      },
    );
  },
};

const mutations: any = {
  toggleSuccess: (userState: any, payload: any) => {
    const toggles = JSON.parse(payload.settings);
    // tslint:disable-next-line:triple-equals
    userState.classes = toggles.classes == 'true';
    // tslint:disable-next-line:triple-equals
    userState.payment = toggles.payment == 'true';
    // tslint:disable-next-line:triple-equals
    userState.events = toggles.events == 'true';
  },
  toggleStatus: (userState: any, message: string) => {
    userState.status = message;
  },
  updatingStatus: (userState: any, result: any) => {
    if (result.successful) {
      userState.payment = result.payment;
      userState.classes = result.classes;
      userState.events = result.events;
    }
    userState.updatingStatus = result.successful ? 'Toggles updated' : 'Toggles failed to update';
    userState.updating = false;
  },
  startUpdate: (userState: any) => {
    userState.updating = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
