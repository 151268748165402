import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ArtType, IRequest, PageKeys } from '@/models/request.interface';
import Admin from '@/components/admin/admin';
import { IArt, Art } from '@/models/art.interface';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { BaseService } from '@/services/base.service';
import store from '@/store/store';
import Header from '@/components/common/header.vue';
import AboutEditor from '@/components/modals/about/aboutEditor';
import { EventBus } from '@/event-bus';

@Component({
  computed: mapGetters({
    art: 'art/Page',
    page: 'pages/aboutPage',
    edit: 'auth/Edit',
    add: 'auth/Add',
    deleteEntry: 'auth/Delete',
    isAuthenticated: 'auth/isAuthenticated',
    videos: 'videos/VideoLinks',
  }),
  components: {
    vueDropzone: vue2Dropzone,
    Admin,
    Header,
    AboutEditor,
  },
})
export default class About extends Vue {
  private art!: any;
  private page!: any;
  private edit!: any;
  private add!: any;
  private deleteEntry!: any;
  private isAuthenticated!: any;
  private videos!: any;

  private api = BaseService.api;

  private loading: boolean = true;
  private videosLoading: boolean = true;
  private pageRequest: IRequest = {
    ArtType: ArtType.Standing,
    PageName: PageKeys.About,
    PageNumber: 1,
    Order: true,
  };

  private dialog: boolean = false;
  private editedItem: IArt = new Art(PageKeys.About);
  private formTitle: string = '';

  private created(): void {
    if (this.isAuthenticated) {
      store.dispatch('auth/adminReset');
      store.watch(
        () => this.add,
        (rst) => {
          if (rst === true) {
            this.editedItem = new Art(PageKeys.About);
            this.dialog = true;
          }
        },
      );
    }
    this.loading = this.art(this.pageRequest.PageName).Content.length === 0;
    if (this.loading) {
      store.dispatch('art/contentRequest', this.pageRequest).then(() => {
        this.loading = false;
      });
    }
    store.dispatch('videos/linkRequest').then((rst) => {
      this.videosLoading = false;
    });
    EventBus.$on('CloseAboutDialog', () => (this.dialog = false));
  }

  private editEntry(item: IArt) {
    this.editedItem = item;
    this.editedItem.artImage = item.artImage;
    this.formTitle = 'Edit';
    this.dialog = true;
  }

  private createEntry() {
    this.editedItem = new Art(PageKeys.About);
    this.formTitle = 'Add';
    this.dialog = true;
  }

  private deleteArtEntry(item: IArt) {
    store.dispatch('art/deleteRequest', {
      id: item.id,
      page: PageKeys.About,
    });
  }

  private fileUploaded(file: any, response: any) {
    this.editedItem.artImage.fileName = response[0];
    this.editedItem.artImage.fileType = response[1];
  }

  private saveItem() {
    if (this.edit) {
      store.dispatch('art/updateRequest', this.editedItem);
    } else {
      store.dispatch('art/addRequest', this.editedItem);
    }
    this.closeDialog();
  }

  private closeDialog() {
    this.dialog = false;
  }
}
