import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import store from '@/store/store';
import router from '@/router';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
  computed: mapGetters({
    choosenDate: 'calendar/UserChoosenDate',
    emailSent: 'calendar/EmailSent',
  }),
})
export default class Groups extends Vue {
  // Getters
  private choosenDate!: any;
  private emailSent!: any;

  private classList: string[] = ['Trays', 'Pendants', 'Nightlight'];

  private name: string = '';
  private email: string[] = [];
  private selectedClass: string = '';
  private radioGroup: string = '';
  private valid: boolean = false;
  private emailCount: number = 1;
  private extraInfo: string = '';
  private loading: boolean = false;
  private diableForm: boolean = false;
  private userMessage: string = '';
  private counter: number = 5;

  private emailRules: any = [
    (v: any) => !!v || 'E-mail is required',
    (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
  ];

  private mounted() {
    if (this.choosenDate == null || this.choosenDate === undefined || this.choosenDate === '') {
      router.push('Classes');
    }
  }

  private get formInstance(): Vue & { validate: () => void } {
    return this.$refs.form as Vue & { validate: () => void };
  }

  private removeEmail(index) {
    this.email.splice(index, 1);
    this.emailCount--;
  }

  private buildMessage() {
    this.loading = true;
    this.diableForm = true;
    let body = 'From: ' + this.name + '\n';
    body += 'Class: ' + this.selectedClass + '\n';
    body += 'Date: ' + this.choosenDate + '\n';
    this.email.forEach((email: string) => {
      body += 'Email: ' + email + '\n';
    });
    body += 'Info: ' + this.extraInfo + '\n';

    store.dispatch('calendar/sendMailRequest', { Body: body }).then(() => {
      this.loading = false;
      if (this.emailSent) {
        this.userMessage = 'Message sent. Redirecting to Classes.';
        setTimeout(() => router.push('Classes'), 5000);
      } else {
        this.userMessage =
          'Sorry, something went wrong. Please try again later. You can also email Mitzi Kugler directly at mitzikart@gmail.com';
      }
    });
  }
}
