import { Gallery } from '@/models/events/gallery.interface';
import { eventService } from '@/services/event.service';
import { Show } from '@/models/events/show.interface';
import { IEventPackage } from '@/models/events/eventPackage.interface';
import { Class } from '@/models/events/class.interface';
import { EventBus } from '@/event-bus';

const state = {
  galleryContent: [],
  showContent: [],
  classContent: [],
};

const getters: any = {
  galleries: (userState: any) => userState.galleryContent as Gallery[],
  shows: (userState: any) => userState.showContent as Show[],
  classes: (userState: any) => userState.classContent as Class[],
};

const actions: any = {
  contentRequest: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    eventService.get().subscribe(
      (result: any) => {
        commit('contentSuccess', result);
      },
      (errors: any) => {
        commit('updateStatus', errors);
      },
    );
  },
  newEvent: ({ commit, dispatch }: { commit: any; dispatch: any }, event: IEventPackage) => {
    eventService.newEvent(event).subscribe(
      (result: any) => {
        commit('gallerySuccess', result);
      },
      (errors: any) => {
        commit('updateStatus', errors);
      },
    );
  },
  updateEvent: ({ commit, dispatch }: { commit: any; dispatch: any }, updateEvent: IEventPackage) => {
    eventService.updateEvent(updateEvent).subscribe(
      (result: any) => {
        commit('gallerySuccess', result);
      },
      (errors: any) => {
        commit('updateStatus', errors);
      },
    );
  },
  deleteEvent: ({ commit, dispatch }: { commit: any; dispatch: any }, deleteEvent: IEventPackage) => {
    eventService.deleteEvent(deleteEvent).subscribe(
      (result: any) => {
        commit('gallerySuccess', result);
      },
      (errors: any) => {
        commit('updateStatus', errors);
      },
    );
  },
};

const mutations: any = {
  contentSuccess: (userState: any, payload: any) => {
    userState.galleryContent = payload.item1;
    userState.showContent = payload.item2;
    userState.classContent = payload.item3;
  },
  gallerySuccess: (userState: any, payload: any) => {
    EventBus.$emit('ReloadWithMessage', `Update Successful`);
  },
  ShowSuccess: (userState: any, payload: any) => {
    EventBus.$emit('ReloadWithMessage', `Update Successful`);
  },
  updateStatus: (userState: any, payload: any) => {
    EventBus.$emit('Alert', payload, payload.indexOf('Successful') !== -1 ? 'info' : 'error');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
